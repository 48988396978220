import React, { useContext, useEffect, useState } from "react";
import { myContext } from "../../utils/provider";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Layout from "../../components/layout/layout-ecommerce";
import SEO from "../../components/layout/seo";
import { CartProvider } from "use-shopping-cart";
import useStripeProducts from "../../components/ecommerce/products/useStripeProducts";
import CartModal from "../../components/ecommerce/store/cartModal";
import { googleAdsConversionTag } from "../../utils/google-ads-track";
import "@fontsource/red-hat-display";
import "@fontsource/red-hat-display/700.css";
import { createTheme } from "@mui/material/styles";
import Catalogue from "../../components/ecommerce/catalogue/index";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import banner from "../../images/ecommerce/ecom_banner.png";
import banner_phone from "../../images/ecommerce/ecom_banner_phone.png";
import { Box, Grid } from "@mui/material";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#3D3838",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = () => {
  const links = ["Tools", "Attachments", "Accessories"];

  const products = useStripeProducts();

  const microRECStripe = products.filter((product) => {
    return product && product.name && product.name.includes("MRC");
  });
  const optiRECStripe = products.filter((product) => {
    return product && product.name && product.name.includes("OPT");
  });
  const quikvueStripe = products.filter((product) => {
    return product && product.name && product.name.includes("QVP");
  });
  const bluetoothStripe = products.filter((product) => {
    return product && product.name && product.name.includes("BTS");
  });

  const takeanepStripe = products.filter((product) => {
    return product && product.name && product.name.includes("NEP");
  });

  const context = useContext(myContext);
  // Add Goggle Ads ID to track conversion events on /store page - for Launch
  useEffect(() => {
    googleAdsConversionTag(process.env.GOOGLE_ADS_ID);
  });

  const getCurrency = () => {
    if (context.ecommerceDollar) {
      return "USD";
    } else {
      return "EUR";
    }
  };

  return (
    <ThemeProvider theme={cs_theme}>
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Container id="main_page" disableGutters maxWidth={false}>
          <Layout helmetName="Catalogue">
            <SEO title="Custom Surgical Store | Catalogue" />
            <CartModal />
            {/* <h1
              style={{
                textAlign: "center",
                fontSize: "38px",
              }}
            >
              Store
            </h1> */}
            <Grid container style={{ marginTop: "120px" }}>
              <Grid
                item
                xs={11}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                {links.map((link) => (
                  <div key={link}>
                    <AnchorLink
                      to={`/store/#${link.toLowerCase()}`}
                      className="store_link"
                    >
                      {link}
                    </AnchorLink>
                  </div>
                ))}
              </Grid>
            </Grid>
            <hr
              style={{
                backgroundColor: "#999999",
                marginTop: "-2px",
                height: "1px",
                marginBottom: "1em",
              }}
            ></hr>
            <Box
              component="img"
              src={banner}
              display={{ xs: "none", sm: "block" }}
              sx={{
                width: "100%",
                maxHeight: "500px",
                objectFit: "contain",
                backgroundColor: "#8F4FFF",
              }}
            />
            <Box
              component="img"
              src={banner_phone}
              display={{ xs: "block", sm: "none" }}
              sx={{
                width: "100%",
                maxHeight: "500px",
                objectFit: "contain",
                backgroundColor: "#8F4FFF",
              }}
            />
            <Catalogue
              optiRECStripe={optiRECStripe[0]}
              microRECStripe={microRECStripe[0]}
              quikvueStripe={quikvueStripe[0]}
              bluetoothStripe={bluetoothStripe[0]}
              takeanepStripe={takeanepStripe[0]}
            />
          </Layout>
        </Container>
      </CartProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
